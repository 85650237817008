// ** React Imports
import { ReactNode } from 'react';

// ** Next Imports
import Link from 'next/link';

// ** MUI Components
import MuiLink from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box, { BoxProps } from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import MuiCard, { CardProps } from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

// ** Third Party Imports
import { object, string } from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// ** Hooks
import { useAuth } from 'src/hooks/useAuth';

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout';
import PasswordInput from 'src/components/PasswordInput';
import ProtechtLogo from 'src/components/ProtectLogo';

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: 400,
  },
}));

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: '0.18px',
  marginBottom: theme.spacing(1.5),
}));

// ** Styled Components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: 450 },
}));

const schema = object().shape({
  email: string().email().required('Email is a required field'),
  password: string().required('Password is a required field'),
});

const defaultValues = {
  password: '',
  email: '',
};

interface FormData {
  email: string;
  password: string;
}

const LoginPage = () => {
  // ** Hooks
  const { loading, login } = useAuth();

  const { control, setError, handleSubmit } = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    const { email, password } = data;

    login({ email, password }, () => {
      setError('email', {
        type: 'manual',
        message: 'Email or Password is invalid',
      });
    });
  };

  return (
    <Box className='content-center'>
      <Card sx={{ zIndex: 1 }}>
        <CardContent
          sx={{
            p: (theme) => `${theme.spacing(13, 7, 6.5)}`,
            pt: 8,
          }}
        >
          <Box
            sx={{
              paddingX: { md: 4, xs: 1 },
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'background.paper',
            }}
          >
            <BoxWrapper>
              <Box
                sx={{
                  top: 30,
                  left: 40,
                  display: 'flex',
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ProtechtLogo width='180' />
              </Box>
              <Box sx={{ mb: 6 }}>
                <TypographyStyled variant='h5'>Log In</TypographyStyled>
                <Typography variant='body2'>
                  Enter your email address and password to access the Protecht
                  dashboard.
                </Typography>
              </Box>
              <form
                noValidate
                autoComplete='off'
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name='email'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      margin='normal'
                      fullWidth
                      autoFocus
                      label='Email'
                      placeholder='j.doe@email.com'
                      sx={{ mt: 0 }}
                      error={Boolean(error?.message)}
                      helperText={error?.message || ' '}
                    />
                  )}
                />
                <Controller
                  name='password'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <PasswordInput
                      {...field}
                      fullWidth
                      label='Password'
                      error={Boolean(error?.message)}
                      helperText={error?.message || ' '}
                      sx={{ mb: 1 }}
                    />
                  )}
                />
                <Link passHref href='/forgot-password/' legacyBehavior>
                  <Typography
                    component={MuiLink}
                    variant='body2'
                    sx={{ color: 'primary.main' }}
                  >
                    Forgot password?
                  </Typography>
                </Link>
                <Button
                  data-testid='login-button'
                  disabled={loading}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                  sx={{ my: 4 }}
                >
                  Log in
                </Button>
              </form>
            </BoxWrapper>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

LoginPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

LoginPage.guestGuard = true;

export default LoginPage;
