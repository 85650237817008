import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import VisibilityOffOutlineIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlineIcon from '@mui/icons-material/VisibilityOutlined';
import { forwardRef, useState } from 'react';

const PasswordInput = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ InputProps, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
      <TextField
        {...props}
        type={showPassword ? 'text' : 'password'}
        inputRef={ref}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOutlineIcon />
                ) : (
                  <VisibilityOffOutlineIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default PasswordInput;
